
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import { buildContentfulImageURL } from '@utils/image';
import Image from '../../../../../components/Image';
import AnchorLink from '../../../../../components/AnchorLink';
import { ReactComponent as Arrow } from '../../../imgs/Back.svg';
import { ReactComponent as ArrowDark } from '../../../imgs/back-dark.svg';
import s from './CarouselItem.module.scss';
const propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    theme: PropTypes.string,
    onItemClick: PropTypes.func
};
const CarouselItem = ({ image = '', title = '', description = '', link = '/', theme = 'Dark', onItemClick = () => { }, }) => (<article className={s.root}>
    <AnchorLink href={link} onClick={(e) => {
        onItemClick(e);
    }} data-testid="carousel-item-link">
      <Image imageSet={[
        {
            src: `${buildContentfulImageURL(image, {
                width: 315,
                height: 315,
                fit: 'fill',
                focus: 'top',
                format: 'webp'
            })} 1x, ${buildContentfulImageURL(image, {
                width: 488,
                height: 488,
                fit: 'fill',
                focus: 'top',
                format: 'webp'
            })} 2x`,
            type: 'image/webp'
        },
        {
            src: `${buildContentfulImageURL(image, {
                width: 315,
                height: 315,
                fit: 'fill',
                focus: 'top',
                format: 'jpg'
            })} 1x, ${buildContentfulImageURL(image, {
                width: 488,
                height: 488,
                fit: 'fill',
                focus: 'top',
                format: 'jpg'
            })} 2x`,
            type: 'image/jpeg'
        },
        {
            src: buildContentfulImageURL(image, {
                width: 315,
                height: 315,
                fit: 'fill',
                focus: 'top',
                format: 'jpg'
            })
        },
    ]} gradient={false} lazy/>
      <h2 className={[s.title, theme === 'Light' ? s.titleDark : ''].join(' ')}>{title}</h2>
      <p className={[s.description, theme === 'Light' ? s.descriptionDark : ''].join(' ')}>
        {description}
      </p>
      <span className={[s.readFeature, theme === 'Light' ? s.readFeatureDark : ''].join(' ')}>
        READ MORE
      </span>
      <span className={s.arrow}>{theme === 'Light' ? <ArrowDark /> : <Arrow />}</span>
    </AnchorLink>
  </article>);
CarouselItem.propTypes = propTypes;
export default CarouselItem;

    async function __Next_Translate__getStaticProps__194cc7a5b63__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionCarousel/CarouselItem/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc7a5b63__ as getStaticProps }
  